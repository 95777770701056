import { PropertyProvider } from "context/propertyContext/PropertyContext";
import useWhyDidYouUpdate from "Hooks/useWhyDidYouUpdate";
import { ReactComponent as PlanIcon } from "images/complaint.svg";
import { ReactComponent as PropertyIcon } from "images/propertyInfo.svg";
import { ReactComponent as HomeIcon } from "images/requirement.svg";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import UpgradeBanner from "v2/components/Banner/UpgradeBanner";
import Layout from "../Layout/Layout";
import GetSupport from "./GetSupport/GetSupport";
import Journey from "./Journey/Journey";
import styles from "./Property.module.css";
import PropertyInfo from "./PropertyInfo/PropertyInfo";
import TabView from "./TabView/TabView";
import { ReactComponent as ActionsRequiredWarning } from "images/actionsRequiredWarning.svg";
import { ReactComponent as ActionsRequiredCritical } from "images/actionsRequiredCritical.svg";
import { Button } from "../../../v2/components/Form";
import { Actions } from "./constants";
import {
  useCustomer,
  usePackages,
  useUserData,
} from "context/environmentContext/EnvironmentContext";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { ModalV2 } from "v2/components";
import useModal from "Hooks/useModal";
import NoUprnMapModal from "./PropertyInfo/noUprnMapModal/noUprnMapModal";
const tabList = [
  {
    name: "Property Report",
    path: "property-info",
    Component: PropertyInfo,
    icon: PropertyIcon,
  },
  {
    name: "Journey",
    path: "journey",
    Component: Journey,
    icon: HomeIcon,
  },
  {
    name: "Get Support (Order)",
    path: "get-support",
    Component: GetSupport,
    icon: PlanIcon,
  },
];

const Property = (props) => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const user = useUserData();
  const customer = useCustomer();
  const packages = usePackages();
  const [actionRequired, setActionRequired] = useState([]);
  const [isModalOpen, openModal, closeModal, ModalData] = useModal();

  const handleOnNext = (currentTabIndex) => {
    if (currentTabIndex < tabList.length) {
      history.push(`${url}/${tabList[currentTabIndex + 1].path}`);
    }
  };
  const handleOnPrevious = (currentTabIndex) => {
    if (currentTabIndex < tabList.length) {
      history.push(`${url}/${tabList[currentTabIndex - 1].path}`);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.history.state?.state?.page]);

  useWhyDidYouUpdate("Property", props);
  const defaultPackages = {
    packages: [
      {
        name: "Licence Application",
        packageId: 5,
        priceInLondon: "500.00",
        priceRestOfUk: "400.00",
      },
      {
        name: "Compliance Guidance",
        packageId: 4,
        priceInLondon: "500.00",
        priceRestOfUk: "400.00",
      },
      {
        name: "Gold",
        packageId: 3,
        priceInLondon: "800.00",
        priceRestOfUk: "700.00",
      },
      {
        name: "Platinum",
        packageId: 2,
        priceInLondon: "1000.00",
        priceRestOfUk: "900.00",
      },
      {
        name: "Diamond",
        packageId: 1,
        priceInLondon: "1500.00",
        priceRestOfUk: "1400.00",
      },
    ],
  };

  const getActionData = (action) => {
    switch (action.value.toLowerCase()) {
      case Actions.MissingUPRN:
        return {
          bannerText:
            "We were unable to match this property to a UPRN, this could affect the accuracy of the report.",
          buttonText: "Verify Address",
        };
      case Actions.ConfirmPin:
        return {
          bannerText:
            "Your property is close to the edge of a compliance scheme",
          buttonText: "Confirm Property Location",
        };
      case Actions.LicenceExpired:
        return {
          bannerText: "Your licence has expired",
          buttonText: "Update Documentation",
        };
      case Actions.LicenceRequired:
        return {
          bannerText: "Licensing is required for this property",
          buttonText: "Update Documentation",
        };
      case Actions.LicenceExpiring:
        return {
          bannerText: "Your licence will expire in the next few months",
          buttonText: "Update Documentation",
        };
      case Actions.DocumentsExpired:
        return {
          bannerText:
            "Action is required urgently, please update your documentation.",
          buttonText: "",
        };
      case Actions.DocumentsExpiring:
        return {
          bannerText:
            "Action is required in the next few months, please update your documentation.",
          buttonText: "",
        };
      case Actions.PlanningRequired:
        return {
          bannerText: "Planning is required for this property",
          buttonText: "Update Documentation",
        };
      case Actions.OverMaxLicenceTenants:
        return {
          bannerText:
            "Current number of tenants over maximum for current licence",
          buttonText: "Update licence",
        };
      case Actions.OverMaxLicenceHouseholds:
        return {
          bannerText:
            "Current number of households over maximum for current licence",
          buttonText: "Update licence",
        };
      case Actions.OverMaxPlanningTenants:
        return {
          bannerText:
            "Current number of tenants over maximum for current planning",
          buttonText: "Update planning",
        };
      case Actions.OverMaxPlanningHouseholds:
        return {
          bannerText:
            "Current number of households over maximum for current planning",
          buttonText: "Update planning",
        };
      default:
        console.error("getActionData: Action not found!", action);
        return {
          bannerText: action.action,
          buttonText: "View Action",
        };
    }
  };

  const ActionBanner = () => {
    if (actionRequired === undefined) {
      return null;
    }

    if (actionRequired == null) {
      return null;
    }

    if (actionRequired.length <= 0) {
      return null;
    }

    return actionRequired.map((action) => {
      const isRed = action.warning === "red" ? true : false;
      const actionData = getActionData(action);

      return (
        <div
          className={
            isRed ? styles.actionsRequiredCritical : styles.actionsRequired
          }
          key={action.action}
        >
          <div className={styles.leftSide}>
            {isRed ? <ActionsRequiredCritical /> : <ActionsRequiredWarning />}
            <span className={styles.warningText}>{actionData.bannerText}</span>
          </div>
          <div className={styles.rightSide}>
            <Button
              variant="bannerButton"
              actionName={actionData.buttonText}
              size="small"
              onClick={
                // action.value === "missing uprn"
                //   ? openModal
                //   : () => history.push(url + action.url)
                () => history.push(url + action.url)
              }
            />
          </div>
        </div>
      );
    })[0];
  };

  return (
    <PropertyProvider
      propertyId={props.match.params.propertyId}
      token={props.match.params.token}
      propertyData={
        props.location?.state?.propertyId ? props.location.state : null
      }
      user={user}
      customer={customer}
      packages={packages[0] ? packages[0] : defaultPackages}
      setPropertyType={packages[2]}
    >
      <Layout>
        <div className={styles.main}>
          <ActionBanner />
          {isModalOpen && (
            <ModalV2 closeModal={closeModal}>
              <NoUprnMapModal closeModal={closeModal} />
            </ModalV2>
          )}
          <TabView tabList={tabList} />
          <Switch>
            {tabList.map(({ path, Component }, tabIndex) => (
              <Route key={path} path={`${url}/${path}`}>
                <Component
                  landlordView={!!props.match.params.token}
                  goHome={() => handleOnPrevious(1)}
                  onNext={() => handleOnNext(tabIndex)}
                  onPrevious={() => handleOnPrevious(tabIndex)}
                  setActionRequired={setActionRequired}
                  actionRequired={actionRequired}
                />
              </Route>
            ))}
            <Route path={`${url}/`}>
              <Redirect
                to={{
                  pathname: `${url}/${tabList[0].path}`,
                  state: {
                    page: tabList[0].name,
                  },
                }}
              />
            </Route>
          </Switch>
          <UpgradeBanner propertyId={props.match.params.propertyId} />
        </div>
      </Layout>
    </PropertyProvider>
  );
};
Property.propType = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  isLogout: PropTypes.bool,
  autoCallApi: PropTypes.bool,
};
export default Property;
